import React from "react"
import { graphql } from "gatsby"
import { Typography, Container, Grid, Button, Box } from "@material-ui/core"
import moment from "moment"
import _ from "lodash"
import { NavigateNext } from "@material-ui/icons"

import LayoutComponent from "../components/layout.component"
import ArticleCardComponent from "../components/articlecard.component"
import SEO from "../components/seo.component"

const Articles = ({ data, location, navigate }) => {
  return (
    <LayoutComponent>
      <SEO title="Articles" canonical={location.href} />
      {_.map(_.slice(data.allStrapiArticle.edges, 2, 3), ({ node }, i) => {
        return (
          <Box pt={5} key={i}>
            <Container maxWidth="xl">
              <Box pb={5}>
                <Typography variant="h5" component="h2">
                  Featured Articles
                </Typography>
              </Box>
              <Grid container alignItems="center" spacing={5}>
                <Grid item md={6}>
                  <img
                    src={node.picture.publicURL}
                    alt={node.picture.name}
                    width="100%"
                  />
                </Grid>
                <Grid item md={6}>
                  <Box display="flex" flexDirection="row" mb={2}>
                    <Typography variant="caption" component="p">
                      {moment(node.createdAt).format("ll")}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="p"
                      color="textSecondary"
                    >
                      . {node.category && node.category.name} .
                    </Typography>
                    <Typography variant="caption" component="p">
                      3 min read
                    </Typography>
                  </Box>
                  <Typography gutterBottom variant="h5" component="h3">
                    {node.title}
                  </Typography>
                  <Typography gutterBottom>{node.excerpt}</Typography>
                  <Button
                    variant="outlined"
                    endIcon={<NavigateNext fontSize="small" />}
                    onClick={() => navigate(`/${node.slug}`)}
                  >
                    Read Article
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Box>
        )
      })}
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography variant="h5" component="h2">
                Latest Articles
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => navigate(`/latest`)}>
                View All
              </Button>
            </Grid>
          </Grid>
          <Box pt={5}>
            <Grid container spacing={3} direction="row">
              {_.map(
                _.slice(data.allStrapiArticle.edges, 0, 12),
                ({ node }, i) => {
                  return (
                    <Grid item xs={12} md={3} key={i}>
                      <ArticleCardComponent node={node} />
                    </Grid>
                  )
                }
              )}
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography variant="h5" component="h2">
                Most Popular Articles
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => navigate(`/latest`)}>
                View All
              </Button>
            </Grid>
          </Grid>
          <Box pt={5}>
            <Grid container spacing={3} direction="row">
              {_.map(
                _.slice(data.allStrapiArticle.edges, 0, 1),
                ({ node }, i) => {
                  return (
                    <Grid item xs={12} md={6} key={i}>
                      <ArticleCardComponent node={node} excerpt={true} />
                    </Grid>
                  )
                }
              )}
              <Grid item xs={12} md={6}>
                {_.map(
                  _.slice(data.allStrapiArticle.edges, 2, 6),
                  ({ node }, i) => {
                    return (
                      <ArticleCardComponent
                        node={node}
                        key={i}
                        horizontal={true}
                      />
                    )
                  }
                )}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </LayoutComponent>
  )
}

export default Articles

export const articlesQuery = graphql`
  query ArticlesQuery {
    allStrapiArticle(sort: { fields: id, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          content
          excerpt
          createdAt
          picture {
            publicURL
          }
          tags {
            id
            name
            slug
          }
          category {
            id
            name
            slug
          }
        }
      }
    }
  }
`
